import * as React from 'react';
import Link from 'gatsby-link';
import { Helmet } from 'react-helmet';

const ContactPage = () => (
    <div>
        <Helmet>
            <title>Stef van Dijk | Contact</title>
        </Helmet>
        <h1>Contact me bruh</h1>
    </div>
);

export default ContactPage;
